import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Button } from "@mui/material";

const SnackBarButton = ({ messageKey, onClick }) => {
  const { t } = useTranslation();
  return (
    <Button size="small" onClick={onClick}>
      {t(messageKey)}
    </Button>
  );
};

SnackBarButton.propTypes = {
  messageKey: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SnackBarButton;
