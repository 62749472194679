import {
  ENQUEUE_SNACKBAR,
  REMOVE_SNACKBAR,
  CLOSE_FIXED_SNACKBAR,
} from "../actions/snackBars";

const snackBarsInitialState = {
  notifications: [],
};
const reducer = (state = snackBarsInitialState, action = {}) => {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.notification,
          },
        ],
      };

    case CLOSE_FIXED_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) =>
            notification.options.persist && notification.id !== action.id,
        ),
      };

    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key !== action.key,
        ),
      };

    default:
      return state;
  }
};

export default reducer;
