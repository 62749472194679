import React from "react";
import PropTypes from "prop-types";
import { useParams, useHistory } from "react-router-dom";
import { InView } from "react-intersection-observer";

import { makeStyles } from "@mui/styles";

const styles = (theme) => {
  // const styles = (theme) => {
  return {
    root: {
      height: "100vh",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        height: "auto",
        minHeight: "100vh",
      },
    },
  };
};

const useStyles = makeStyles((theme) => styles(theme));

const FullHeightSection = (props) => {
  const { section, subSection } = useParams();
  const history = useHistory();
  const { sectionKey, subSectionKey, children, baseUrl, spyEnabled } = props;
  const classes = useStyles(props);

  const handleChangeView = (inView) => {
    if (
      inView &&
      (sectionKey !== section || subSectionKey !== subSection) &&
      spyEnabled
    ) {
      history.replace(
        `${baseUrl}${sectionKey}${subSectionKey ? `/${subSectionKey}` : ""}`,
      );
    }
  };

  return (
    <InView threshold={0.6} onChange={(inView) => handleChangeView(inView)}>
      <div
        className={classes.root}
        id={`section-${sectionKey}${subSectionKey ? `-${subSectionKey}` : ""}`}
      >
        {children}
      </div>
    </InView>
  );
};

FullHeightSection.defaultProps = {
  baseUrl: "/",
  children: <span />,
  subSectionKey: null,
};

FullHeightSection.propTypes = {
  sectionKey: PropTypes.string.isRequired,
  subSectionKey: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  baseUrl: PropTypes.string,
  children: PropTypes.node,
  spyEnabled: PropTypes.bool.isRequired,
};

export default FullHeightSection;
