import React from "react";
import Home from "../domains/home";
// import Contact from "../domains/contact";
// import Jobs from "../domains/jobs";

export const sections = [
  {
    key: "home",
    component: <Home />,
  },
  // {
  //   key: "contact",
  //   component: <Contact />,
  // },
  // {
  //   key: "jobs",
  //   subSections: [
  //     {
  //       key: "it-production-manager",
  //       component: <Jobs titleKey="it-production-manager" />,
  //     },
  //     {
  //       key: "principal-software-engineer",
  //       component: <Jobs titleKey="principal-software-engineer" />,
  //     },
  //   ],
  // },
];
