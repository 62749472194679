import React from "react";
import { Route, Switch, HashRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { I18nextProvider } from "react-i18next";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { SnackbarProvider } from "notistack";
import classNames from "classnames";
import Polyfills from "./libs/polyfills";

import Login from "./components/Login";

import Body from "./components/Body";
import LoadingSpinner from "./components/loadingSpinner";
import SnackBarContainer from "./components/snackBars/SnackBarContainer";

import i18n from "./libs/i18n";
import { theme } from "./utils/theme";
import { sections } from "./config/sections";

Polyfills.init();
const styles = () => ({
  contentLoading: {
    height: "100vh",
    overflow: "hidden",
  },
});
const useStyles = makeStyles(() => styles());
const App = () => {
  const classes = useStyles();
  const isLoading = useSelector((state) => state.loader.isLoading);
  const sectionsString = sections.map((s) => s.key).join("|");
  return (
    <div className="App">
      <I18nextProvider i18n={i18n}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={4000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <SnackBarContainer />
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {isLoading && <LoadingSpinner />}
            <div className={classNames(isLoading && classes.contentLoading)}>
              <HashRouter>
                <Switch>
                  <Route path={`/:section(${sectionsString})/:subSection?`}>
                    <Body />
                  </Route>
                  <Route path="/login">
                    <Login />
                  </Route>
                  <Route exact path="/">
                    <Body />
                  </Route>
                </Switch>
              </HashRouter>
            </div>
          </ThemeProvider>
        </SnackbarProvider>
      </I18nextProvider>
    </div>
  );
};

export default App;
