import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";

// import Logo from "../../assets/images/logo-big.svg";
import { grayText } from "../../utils/theme";
import VideoPlayer from "../../components/Video";

const styles = (theme) => ({
  root: {
    position: "relative",
    height: "100%",
    minHeight: "100vh",
    paddingTop: "15vh",
    [theme.breakpoints.down("md")]: {
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      paddingTop: "0",
      // backgroundImage: "url(/images/bg.png)",
    },
  },
  title: {
    fontFamily: "'Poppins', sans-serif !important",
    fontWeight: "700 !important",
    fontSize: "48px  !important",
    lineHeight: "72px  !important",
    margin: "0 0 0 0 !important",
    [theme.breakpoints.down("md")]: {
      fontSize: "24px  !important",
      lineHeight: "36px  !important",
    },
  },
  logo: {
    height: "37vh",
    width: "auto",
    marginBottom: "8vh",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      width: "70vw",
    },
  },
  description: {
    color: grayText,
    maxWidth: "90vw",
    marginBottom: "5vh",
    "& h6": {
      fontFamily: "'Poppins', sans-serif !important",
      fontWeight: "400 !important",
      fontSize: "24px !important",
      lineHeight: "32px !important",
    },
    [theme.breakpoints.down("md")]: {
      "& h6": {
        fontSize: "16px  !important",
        lineHeight: "25px  !important",
        maxWidth: "60vw",
        marginTop: theme.spacing(3),
      },
    },
  },
  descriptionText: {
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
    },
  },
  mailButton: {
    fontSize: "16px  !important",
    paddingLeft: `${theme.spacing(10)} !important`,
    paddingRight: `${theme.spacing(10)} !important`,
    paddingTop: `${theme.spacing(3)} !important`,
    paddingBottom: `${theme.spacing(3)} !important`,
    height: "80px  !important",
    [theme.breakpoints.down("md")]: {
      position: "absolute !important",
      bottom: theme.spacing(3),
      left: theme.spacing(3),
      width: `calc(100% - ${theme.spacing(6)})`,
      paddingTop: `${theme.spacing(0)} !important`,
      paddingBottom: `${theme.spacing(0)} !important`,
      height: "65px  !important",
    },
  },
  videoLogo: {
    height: "37vh",
    width: "40vw",
    marginBottom: "8vh",
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      height: "30vh",
    },
  },
});
const useStyles = makeStyles((theme) => styles(theme));
const Home = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box
      className={classes.root}
      justifyContent="center"
      alignItems="center"
      display="flex"
      flexDirection="column"
    >
      {/* <img src={Logo} alt={t("home.title")} className={classes.logo} /> */}
      <Box className={classes.videoLogo}>
        <VideoPlayer
          videoSrc="/video/dcy.mp4"
          videoSrcMobile="/video/dcy-small.mp4"
        />
      </Box>
      <Box justifyContent="center" alignItems="center" display="flex">
        <Typography
          component="h1"
          variant="h1"
          color="secondary"
          className={classes.title}
        >
          <span dangerouslySetInnerHTML={{ __html: t("home.title") }} />
        </Typography>
        <Typography component="h5" variant="h5" color="secondary">
          <span dangerouslySetInnerHTML={{ __html: t("home.subtitle") }} />
        </Typography>
      </Box>
      <Box
        className={classes.description}
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        <Typography
          component="h6"
          variant="h6"
          className={classes.descriptionText}
        >
          <span dangerouslySetInnerHTML={{ __html: t("home.description") }} />
        </Typography>
      </Box>
      <Button
        onClick={() => {
          window.location = `mailto:${t("contact.mail")}`;
        }}
        variant="contained"
        className={classes.mailButton}
        color="primary"
      >
        {t("home.mail")}
      </Button>
    </Box>
  );
};

export default Home;
