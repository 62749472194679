export const scrollToSection = (sectionId, subSection) => {
  const section = document.getElementById(
    `section-${sectionId}${subSection ? `-${subSection}` : ""}`,
  );
  if (section) {
    window.scrollTo({
      behavior: "smooth",
      top: section.offsetTop,
    });
  }
};

export const navigate = (setSpyEnabled, section, reDirect) => {
  if (section) {
    setSpyEnabled(false);
    const firstSubKey =
      section.subSections && section.subSections[0]
        ? section.subSections[0].key
        : null;
    const sectionDom = document.getElementById(
      `section-${section.key}${firstSubKey ? `-${firstSubKey}` : ""}`,
    );
    reDirect(`/${section.key}${firstSubKey ? `/${firstSubKey}` : ""}`);
    if (sectionDom) {
      window.scrollTo({
        behavior: "smooth",
        top: sectionDom.offsetTop,
      });
    }
    setTimeout(() => {
      setSpyEnabled(true);
    }, 1000);
  }
};
