import React from "react";
import {
  // useHistory,
  Link,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
// import {
//   useDispatch,
//   useSelector,
// } from "react-redux";
import PropTypes from "prop-types";
// import Hidden from "@mui/material/Hidden";

import {
  AppBar,
  Toolbar,
  // Tab,
  // Tabs,
  // Button,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Logo from "../assets/images/logo-small.svg";

// import { setProfile } from "../redux/actions/user";
// import { sections } from "../config/sections";
// import { navigate } from "../utils";

const styles = (theme) => ({
  root: {
    width: "100%",
    background: `${theme.palette.background.default} !important`,
    boxShadow: "none !important",
  },
  toolbar: {
    height: theme.spacing(18),
    paddingLeft: `${theme.spacing(6)} !important`,
    paddingRight: `${theme.spacing(6)} !important`,
    [theme.breakpoints.down("md")]: {
      height: theme.spacing(6),
      paddingLeft: `${theme.spacing(2)} !important`,
      paddingRight: `${theme.spacing(0)} !important`,
    },
  },
  homeLink: {
    display: "flex",
    height: theme.spacing(18),
    [theme.breakpoints.down("md")]: {
      height: theme.spacing(8),
    },
  },
  logo: {
    height: "auto",
    width: "10vw",
    [theme.breakpoints.down("md")]: {
      width: "30vw",
    },
  },
  tabs: {
    width: "100%",
  },
  tab: {
    paddingBottom: `${theme.spacing(0)} !important`,
    paddingTop: `${theme.spacing(0)} !important`,
    height: theme.spacing(4),
    minHeight: theme.spacing(4),
    textTransform: "none !important",
  },
  boxProfile: {
    width: "10vw",
  },
});

const useStyles = makeStyles((theme) => styles(theme));
const TopBar = () =>
  // props
  {
    // const {
    //   currentSection,
    //   setSpyEnabled,
    // } = props;
    const classes = useStyles();
    // const history = useHistory();
    // const dispatch = useDispatch();
    const { t } = useTranslation();
    // const userProfile = useSelector((state) => state.user.profile);
    // const handleLogout = () => {
    //   dispatch(setProfile(null));
    //   history.push("/login");
    // };
    // const handleLogin = () => {
    //   history.push("/login");
    // };
    // const handleChangeTab = (newSectionKey) => {
    //   const section = sections.find((s) => s.key === newSectionKey);
    //   navigate(setSpyEnabled, section, history.replace);
    // };
    return (
      <AppBar position="fixed" className={classes.root}>
        <Toolbar className={classes.toolbar}>
          {/* <Hidden mdDown> */}
          <Link to="/" className={classes.homeLink}>
            <img className={classes.logo} src={Logo} alt={t("topBar.title")} />
          </Link>
          {/* </Hidden> */}

          {/* <Tabs
          className={classes.tabs}
          centered
          value={
            currentSection && currentSection.key ? currentSection.key : false
          }
          onChange={(event, newSectionKey) => handleChangeTab(newSectionKey)}
        >
          {sections.map((section) => {
            if ((!userProfile && !section.hiddenLogout) || userProfile) {
              return (
                <Tab
                  classes={{
                    root: classes.tab,
                  }}
                  value={section.key}
                  key={section.key}
                  label={t(`sections.${section.key}`)}
                />
              );
            }
            return null;
          })}
        </Tabs> */}

          <Box className={classes.boxProfile}>
            {/* {userProfile && (
            <Button onClick={() => handleLogout()} variant="outlined">
              {t("topBar.logout")}
            </Button>
          )}
          {!userProfile && (
            <Button onClick={() => handleLogin()} variant="outlined">
              {t("topBar.login")}
            </Button>
          )} */}
          </Box>
        </Toolbar>
      </AppBar>
    );
  };

TopBar.defaultProps = {
  currentSection: null,
};

TopBar.propTypes = {
  currentSection: PropTypes.object,
  setSpyEnabled: PropTypes.func.isRequired,
};
export default TopBar;
