import React, { useState } from "react";
import {
  Box,
  Paper,
  FormControl,
  InputAdornment,
  IconButton,
  InputLabel,
  OutlinedInput,
  Typography,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import classNames from "classnames";
import sjcl from "sjcl";

import { theme } from "../utils/theme";

const styles = () => ({
  root: {
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center center",
    // backgroundSize: "cover",
    // backgroundImage: "url(/images/bg.png)",
    position: "relative",
    height: "100vh",
  },
  form: {
    width: 350,
    padding: theme.spacing(3, 4),
  },
  formControl: {
    width: "100%",
  },
  contact: {
    fontSize: 12,
    "& a": {
      color: theme.palette.secondary.text,
    },
  },
  homeLink: {
    textDecoration: "none",
    color: theme.palette.secondary.text,
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

const encodePassword = (password) => {
  const bitArray = sjcl.hash.sha256.hash(password);
  const digestSha256 = sjcl.codec.hex.fromBits(bitArray);
  return digestSha256;
};

const useStyles = makeStyles(() => styles());
const Login = () => {
  const classes = useStyles();
  const [showPassword, serShowPassword] = useState(false);
  const [password, serPassword] = useState("");
  const [setEncodedPassword] = useState(null);
  const [isTouched, setIstouched] = useState({
    login: false,
    password: false,
  });
  const [login, serLogin] = useState("");
  const { t } = useTranslation();

  // const handleLogin = (data) => {
  //   dispatch(setIsLoading(true));
  //   Api.post("/auth/login", data)
  //     .then((profile) => {
  //       dispatch(setProfile(profile));
  //       history.push("/home");
  //     })
  //     .catch((error) => {
  //       dispatch(enqueueSnackbar(errorSnackBar("login.error", error)));
  //     });
  // };
  const isFormValid = password !== "" && login !== "";

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (newPassword) => {
    serPassword(newPassword);
    setEncodedPassword(encodePassword(newPassword));
  };

  const handleSubmit = () => {
    // const data = {
    //   login,
    //   password: encodedPassword,
    // };
    // handleLogin(data);
  };

  const handleBlur = (keyName) => {
    setIstouched({
      ...isTouched,
      [keyName]: true,
    });
  };

  const handleKeyPressed = (event) => {
    if ((event.which === 13 || event.keyCode === 13) && isFormValid) {
      handleSubmit();
    }
  };

  return (
    <Box
      className={classes.root}
      justifyContent="center"
      alignItems="center"
      display="flex"
    >
      <Paper className={classes.form} elevation={3}>
        <Box mb={3}>
          <Typography
            variant="h4"
            color="secondary"
            className={classes.marginBottom}
          >
            {t("login.title")}
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body1" className={classes.marginBottom}>
            {t("login.desc")}
          </Typography>
        </Box>

        <form>
          <Box mb={2}>
            <FormControl
              variant="outlined"
              className={classNames(classes.formControl, classes.marginBottom)}
            >
              <InputLabel htmlFor="login">{t("login.username")}</InputLabel>
              <OutlinedInput
                error={isTouched.login && login === ""}
                labelWidth={70}
                id="login"
                type="text"
                value={login}
                disabled // remove this
                onKeyPress={(event) => handleKeyPressed(event)}
                inputProps={{
                  onBlur: () => handleBlur("login"),
                }}
                onChange={(event) => serLogin(event.target.value)}
              />
            </FormControl>
          </Box>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="password">{t("login.password")}</InputLabel>
            <OutlinedInput
              onKeyPress={(event) => handleKeyPressed(event)}
              error={isTouched.password && password === ""}
              labelWidth={70}
              id="password"
              type={showPassword ? "text" : "password"}
              value={password}
              disabled // remove this
              onChange={(event) => handlePasswordChange(event.target.value)}
              inputProps={{
                onBlur: () => handleBlur("password"),
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    disabled // remove this
                    onClick={() => serShowPassword(!showPassword)}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Box mt={3} justifyContent="flex-end" display="flex">
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              disabled={!isFormValid}
            >
              {t("login.button")}
            </Button>
          </Box>
          <Box mt={2}>
            <div
              className={classes.contact}
              dangerouslySetInnerHTML={{ __html: t("login.contact") }}
            />
          </Box>
          <Box mt={2} justifyContent="flex-end" display="flex">
            <Link to="/" className={classes.homeLink}>
              {t("login.backToHome")}
            </Link>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default Login;
