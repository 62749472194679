import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "i18next";

import enTranslations from "./translations/en.json";

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: { translation: enTranslations },
  },
  fallbackLng: "en",
  debug: true,

  // have a common namespace used around the full app
  // ns: ["translations"],
  // defaultNS: "translations",

  interpolation: {
    formatSeparator: ",",
  },

  react: {
    wait: true,
    transSupportBasicHtmlNodes: true,
  },
});

export default i18n;
