import React from "react";

import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

import PropTypes from "prop-types";
import classNames from "classnames";

const styles = () => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    zIndex: "10000",
  },
  rootTransparent: {
    backgroundColor: "transparent",
  },
});

const useStyles = makeStyles(() => styles());
const LoadingSpinner = ({ size, transparent }) => {
  const classes = useStyles();
  return (
    <div
      className={classNames(
        classes.root,
        transparent && classes.rootTransparent,
      )}
    >
      <CircularProgress size={size} color="secondary" />
    </div>
  );
};

LoadingSpinner.defaultProps = {
  size: 40,
  transparent: false,
};

LoadingSpinner.propTypes = {
  classes: PropTypes.object,
  size: PropTypes.number,
  transparent: PropTypes.bool,
};

export default LoadingSpinner;
