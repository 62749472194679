import { applyMiddleware, createStore, combineReducers } from "redux";
import logger from "redux-logger";
import { save, load } from "redux-localstorage-simple";

import thunk from "redux-thunk";

import user from "./redux/reducers/user";
import snackBars from "./redux/reducers/snackBars";
import loader from "./redux/reducers/loader";
import home from "./redux/reducers/home";

const middlewares = [thunk, logger, save()];
const reducers = { user, snackBars, loader, home };
const rootReducer = combineReducers(reducers);

const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);
export default createStoreWithMiddleware(rootReducer, load());
