import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { sections } from "../config/sections";
import FullHeightSection from "./FullHeightSection";
import { scrollToSection } from "../utils";

import TopBar from "./TopBar";

const Body = () => {
  const params = useParams();
  const currentSectionKey = params.section || "home";
  const currentSection = sections.find((s) => s.key === currentSectionKey);
  const [spyEnabled, setSpyEnabled] = useState(false);
  useEffect(() => {
    let timer = 0;
    if (params.section) {
      scrollToSection(params.section, params.subSection);
      timer = 1000;
    }
    setTimeout(() => {
      setSpyEnabled(true);
    }, timer);
  }, []);

  return (
    <>
      <TopBar
        currentSection={currentSection}
        setSpyEnabled={(enabled) => setSpyEnabled(enabled)}
      />
      <section>
        {sections.map((s) => {
          if (s.subSections) {
            return s.subSections.map((ss) => (
              <FullHeightSection
                spyEnabled={spyEnabled}
                setSpyEnabled={(enabled) => setSpyEnabled(enabled)}
                key={ss.key}
                sectionKey={s.key}
                subSectionKey={ss.key}
              >
                {ss.component}
              </FullHeightSection>
            ));
          }
          return (
            <FullHeightSection
              spyEnabled={spyEnabled}
              setSpyEnabled={(enabled) => setSpyEnabled(enabled)}
              key={s.key}
              sectionKey={s.key}
            >
              {s.component}
            </FullHeightSection>
          );
        })}
      </section>
    </>
  );
};

export default Body;
