import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import videojs from "video.js";

import "video.js/dist/video-js.css";

const styles = () => ({
  root: {
    "& .video-js": {
      background: "transparent !important",
    },
    "& .video-js .vjs-modal-dialog": {
      background: "transparent !important",
    },
  },
});
const useStyles = makeStyles((theme) => styles(theme));
const VideoPlayer = ({ videoSrc, videoSrcMobile }) => {
  const playerRef = useRef();
  const classes = useStyles();
  let player;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    player = videojs(
      playerRef.current,
      {
        autoplay: true,
        muted: true,
        loop: true,
      },
      () => {
        player.src(isMobile ? videoSrcMobile : videoSrc);
      },
    );

    return () => {
      player.dispose();
    };
  }, []);

  return (
    <Box className={classes.root}>
      <div data-vjs-player>
        <video ref={playerRef} className="video-js vjs-16-9" playsInline />
      </div>
    </Box>
  );
};
VideoPlayer.propTypes = {
  videoSrc: PropTypes.string.isRequired,
  videoSrcMobile: PropTypes.string.isRequired,
};
export default VideoPlayer;
