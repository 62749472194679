import { createTheme } from "@mui/material/styles";

import { grey } from "@mui/material/colors";

export const green = "#98D3BB";
export const grayText = "#bdbdbd";
export const grayField = "#131418";
export const grayStroke = "#383838";

// eslint-disable-next-line import/no-mutable-exports
let customTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#ffffff",
    },
    divider: "rgba(252, 252, 252, 0.2)",
    background: {
      default: grayField,
      paper: "#16171a",
    },
    text: {
      primary: "#fff",
      secondary: grey[500],
    },
  },
});

customTheme = createTheme(customTheme, {
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#16171a",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "titleCardBord" },
          style: {
            fontSize: "16px",
            lineHeight: "160%",
            letterSpacing: "1.15px",
          },
        },
        {
          props: { variant: "contentSmallCard" },
          style: {
            fontSize: "32px",
            lineHeight: "133%",
            fontWeight: "300",
            color: "#F2F2F2",
          },
        },
        {
          props: { variant: "h1" },
          style: {
            [customTheme.breakpoints.up("xs")]: {
              fontSize: customTheme.spacing(3),
            },
            [customTheme.breakpoints.up("sm")]: {
              fontSize: customTheme.spacing(6),
            },
            letterSpacing: 1,
            fontStyle: "normal",
            marginBottom: customTheme.spacing(4),
          },
        },
        {
          props: { variant: "h2" },
          style: {
            [customTheme.breakpoints.up("xs")]: {
              fontSize: customTheme.spacing(3),
            },
            [customTheme.breakpoints.up("sm")]: {
              fontSize: customTheme.spacing(5),
            },
            letterSpacing: 1,
            fontStyle: "normal",
            marginBottom: customTheme.spacing(4),
          },
        },
        {
          props: { variant: "pageTitle" },
          style: {
            [customTheme.breakpoints.up("xs")]: {
              fontSize: customTheme.spacing(3),
            },
            [customTheme.breakpoints.up("sm")]: {
              fontSize: customTheme.spacing(6),
            },
            fontWeight: "bold",
            letterSpacing: 1,
            fontStyle: "normal",
            marginBottom: customTheme.spacing(0),
          },
        },
        {
          props: { variant: "h3" },
          style: {
            fontSize: "16px",
            fontWeight: "bold",
            letterSpacing: "0.15px",
            marginBottom: customTheme.spacing(1),
          },
        },
        {
          props: { variant: "body1" },
          style: {
            fontSize: "20px",
            letterSpacing: "0.75px",
            lineHeight: "32px",
          },
        },
        {
          props: { variant: "body2" },
          style: {
            fontSize: "18px",
            letterSpacing: "0.75px",
            lineHeight: "32px",
            color: grayText,
          },
        },
        {
          props: { variant: "subtitle1" },
          style: {
            fontSize: "20px",
            lineHeight: "32px",
            textAlign: "center",
            letterSpacing: "0.75px",
            opacity: 0.8,
          },
        },
      ],
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            backgroundColor: grayField,
            width: "100%",
            "& label": {
              color: "white",
            },

            "& fieldset": {
              border: `2px solid ${grayStroke}`,
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "0px",
            },
            "& .MuiTextField-root": {
              backgroundColor: "transparent",
            },
          },
        },
      ],
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "text" },
          style: {
            color: "#fff",
            fontWeight: "bold",
            padding: 0,
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            height: "3.5rem",
            paddingLeft: customTheme.spacing(5),
            paddingRight: customTheme.spacing(5),
          },
        },
        {
          props: { variant: "contained" },
          style: {
            backgroundColor: "#fff",
            "&:hover": {
              background: grayField,
              color: "#fff",
              border: "1px solid #fff",
            },
            "&:disabled": {
              backgroundColor: "rgba(255, 255, 255, 0.25)",
              color: grayField,
            },
            color: grayField,
            borderRadius: 0,
          },
        },
        {
          props: { variant: "contained-form" },
          style: {
            backgroundColor: "#fff",
            "&:hover": {
              background: grayField,
              color: "#fff",
              border: "1px solid #fff",
            },
            "&:disabled": {
              backgroundColor: "rgba(255, 255, 255, 0.25)",
              color: grayField,
            },
            width: "100%",
            color: grayField,
            fontWeight: 600,
            padding: "16.5px 14px",
            borderRadius: 0,
          },
        },
      ],
      defaultProps: {
        variant: "contained",
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: customTheme.palette.background.paper,
          border: "none",
        },
        columnHeaderTitle: {
          fontWeight: "bold",
          fontSize: 16,
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          backgroundColor: green,
          border: "none",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: customTheme.spacing(0, 2, 2, 0),
        },
      },
    },
  },
});
export { customTheme as theme };
